@use './assets/style/Params' as p;

html {
    @media (max-width :499px) {
        font-size:70%;
    }
    @media (min-width :500px) {
        font-size:75%;
    }
    @media (min-width :800px) {
        font-size:85%;
    }
    @media (min-width :1000px) {
        font-size:85%;
    }
    @media (min-width :1150px) {
        font-size:92.5%;
    }
    @media (min-width :1300px) {
        font-size:100%;
    }
    @media (min-width :1520px) {
        font-size:110%;
    }
    @media (min-width :1700px) {
        font-size:120%;
    }
}

body {
    color: p.$palette-text-light;
    // background-image: url('assets/images/cement.webp');
    position: relative;
}

@keyframes scrollbackground {
    0% { transform: translate(0%); }
   50% { transform: translate(-50vw); }
  100% { transform: translate(0%); }
}

body:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    min-width: 150vw;
    min-height: 100vh;
    background-image: url('assets/images/burgerhouse2.webp');
    // background-image: url('assets/images/burgerhouse.jpeg');
    // background-color: rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.8);
    background-blend-mode: darken;
    background-position: center;
    background-size: cover;
    animation: scrollbackground 300s linear infinite;
}

body,
#root {
    width: 100vw;
    min-height:100vh;
    max-height: 100vh;
    overflow-x: hidden;
}

#root {
    position:relative;
    display: grid;
    grid-template-rows: auto max-content;
}

header,
main,
footer {
    width: 100%;
}

footer {
    height: 2rem;
    position: relative;
    background-color: rgba(p.$palette-background, 0.75);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 70%;
    padding-left: 4rem;
    padding-right: 4rem;
    a {
        color: #FFFFFF;
        text-transform: none;
        transition: color 200ms linear;
        &:hover {
            color: p.$palette-primary;
        }
    }
}

a {
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

input {
    color: p.$palette-text-dark;
}

.feedback-error {
    color: p.$palette-primary;
}